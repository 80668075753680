<script>
	import Base from '@backend/Base.vue';

	export default {
		name: "BoStaticSeo",
		extends: Base,
		data() {
			return {
				Name: "BoStaticSeo",
				mrLevel: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List <span
											v-if="data.total">({{ data.total}} )</span></h5>
								</div>
								<div class="col-sm-3"></div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="ss_page"></SortField>
									</th>
									<th>{{fields.ss_title_id}}</th>
									<th>{{fields.ss_keyword_id}}</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.ss_page}}</td>
									<td>{{v.ss_title_id}}</td>
									<td>{{v.ss_keyword_id}}</td>
									<td class="btn-action text-center">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="ss_page" v-model="row.ss_page" :attr="{readonly:true}"></BoField>
						</div>
						<div class="col-sm-6"></div>

						<div class="col-sm-6">
							<BoField name="ss_title_id" v-model="row.ss_title_id"></BoField>
							<BoField name="ss_keyword_id">
								<TagsInput name="ss_keyword_id" v-model="row.ss_keyword_id"	:attr="validation('ss_keyword_id')">
								</TagsInput>
							</BoField>
							<BoField name="ss_desc_id">
								<textarea v-model="row.ss_desc_id" rows="4" class="form-control" :attr="validation('ss_desc_id')"></textarea>
							</BoField>
						</div>
						<div class="col-sm-6">							
							<BoField name="ss_title_en" v-model="row.ss_title_en"></BoField>
							<BoField name="ss_keyword_en">
								<TagsInput name="ss_keyword_en" v-model="row.ss_keyword_en"	:attr="validation('ss_keyword_en')">
								</TagsInput>
							</BoField>
							<BoField name="ss_desc_en">
								<textarea v-model="row.ss_desc_en" rows="4" class="form-control" :attr="validation('ss_desc_en')"></textarea>
							</BoField>
						</div>
						<div class="col-xs-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i
										class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>